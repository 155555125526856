import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { constants } from "./config/constants.js"
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import messages_it from "./config/i18n/it.json";
import messages_en from "./config/i18n/en.json";
import { connect } from "react-redux"

const mapStateToProps = state => {
  return {
    selectedLocale: state.locale
  }
}
const messages = {
  'it-IT': messages_it,
  'en-EN': messages_en
};

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const CambioPassword = React.lazy(() => import('./views/Pages/CambioPassword'));
// A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD)
const CambioPasswordEsterno = React.lazy(() => import('./views/Pages/CambioPasswordEsterno'));
// A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD) - FINE
const RecuperoPassword = React.lazy(() => import('./views/Pages/RecuperoPassword'));

// i18n
addLocaleData([...en, ...it]);



class App extends Component {
  render() {
    return (
      <IntlProvider
        key={this.props.selectedLocale}
        locale={this.props.selectedLocale}
        formats={constants.formats}
        defaultFormats={constants.formats}
        messages={messages[this.props.selectedLocale]}>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/cambioPwd" name="Cambio password" render={props => <CambioPassword {...props}/>} />
              {/* A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD) */}
              <Route exact path="/cambioPwdEsterno" name="Cambio password" render={props => <CambioPasswordEsterno {...props}/>} />
              {/* A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD) - FINE */}
              <Route exact path="/recuperoPwd" name="Recupero password" render={props => <RecuperoPassword {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
            <ToastContainer />
          </React.Suspense>
        </HashRouter>
      </IntlProvider>
    );
  }
}

export default connect(mapStateToProps)(App)