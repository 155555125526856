// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// A. Stefanutti - Modifiche a seguito di UAT A2A 05/2021
// REQ_FUN_UAT_202105_001
// Centralizzazione dello state per i valori di ricerca dei reports.
import { createStore, applyMiddleware } from "redux"
import Reducer from "./store/Reducer"
import { Provider } from "react-redux"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
import thunk from "redux-thunk"
import { AuthProvider } from "react-oidc-context"; // A. Stefanutti - Integrazione OAuth in SF (https://trello.com/c/ZH83dsVf)

const persistConfig = {
  key: 'root',
  storage
}
const persistedReducer = persistReducer(persistConfig, Reducer)
const store = createStore(persistedReducer, applyMiddleware(thunk))
const persistor = persistStore(store)

// A. Stefanutti - Integrazione OAuth in SF (https://trello.com/c/ZH83dsVf)
const oauthConfig = {
  authority: process.env.REACT_APP_OAUTH_PROVIDER_NAME,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  client_secret: ((process.env.REACT_APP_OAUTH_CLIENT_SECRET && process.env.REACT_APP_OAUTH_CLIENT_SECRET!=="") ? process.env.REACT_APP_OAUTH_CLIENT_SECRET : ""),
  redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
  scope: process.env.REACT_APP_OAUTH_SCOPE,
  response_type: "code",
  metadata: {
    authorization_endpoint: process.env.REACT_APP_OAUTH_ENDPOINT_AUTHORIZE,
    token_endpoint: process.env.REACT_APP_OAUTH_ENDPOINT_TOKEN,
    end_session_endpoint: process.env.REACT_APP_OAUTH_ENDPOINT_END_SESSION,
    revocation_endpoint: process.env.REACT_APP_OAUTH_ENDPOINT_REVOKE
  },
};

ReactDOM.render(
  <AuthProvider {...oauthConfig}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>
  </AuthProvider>,
  document.getElementById('root')
);
// A. Stefanutti - Integrazione OAuth in SF (https://trello.com/c/ZH83dsVf) - FINE

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
